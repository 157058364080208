<template>
    <el-dialog title="新增礼品卡" :visible.sync="centerDialogVisible" width="700px" center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" style="padding: 0px 30px 0 10px" label-width="80px" class="demo-ruleForm">
            <el-form-item label="储存卡名称" prop="name">
                <el-input v-model="ruleForm.cardName"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="面值" prop="name">
                        <el-input v-model.number="ruleForm.faceValue"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="赠送金额" prop="name">
                        <el-input v-model.number="ruleForm.giveValue"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="标记" prop="region">
                <el-input v-model="ruleForm.markContent"></el-input>
                <!-- <el-autocomplete style="width: 100%;" class="inline-input" v-model="ruleForm.markContent"
                    :fetch-suggestions="querySearch" placeholder="请选择标记内容" @select="handleSelect"></el-autocomplete> -->
            </el-form-item>
            <el-form-item label="生成数量" prop="number">
                <el-input v-model.number="ruleForm.number"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="卡号前缀" prop="cardNoLetter">
                        <el-input v-model="ruleForm.cardNoLetter" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="卡号位数" prop="cardNoNum">
                        <el-input v-model="ruleForm.cardNoNum"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-form-item label="卡密" prop="type">
                <el-input v-model="ruleForm.cardPassword">
                    <template slot="append">位数</template>
                </el-input>
            </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="medium" @click="centerDialogVisible = false">取 消</el-button>
            <el-button size="medium" type="primary" @click="sub">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            centerDialogVisible: false,
            ruleForm: {
               
            },
            rules: {
                // cardName: [
                //     { required: true, message: '请输入活动名称', trigger: 'blur' },
                //     { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                // ],
                number: [
                    { required: true, message: '请输入生成数量', trigger: 'blur' }
                ],
                cardNoLetter: [
                    { required: true, message: '请输入卡号前缀', trigger: 'blur' }
                ],
                cardNoNum: [
                    { required: true, message: '请输入卡号位数', trigger: 'blur' }
                ],
            },
        };
    },
    created() {

    },
    methods: {
        init() {
            this.centerDialogVisible = true
            this.ruleForm = {}
            this.$refs.ruleForm.clearValidate()
        },
        sub(){
            var that = this;
            this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                that.$http.post("/giftcards/insertBatch", that.ruleForm).then(function (response) {
                console.log(response.data.data, 'response.data.data');
                if (response.data.code == 200) {
                    console.log(response)
                    that.centerDialogVisible = false
                    that.$emit('refresh')
                }
                });
            }
            });
        },
        cardNoChange(val){}

    }
};
</script>