<template>
    <el-dialog title="新增礼品卡" :visible.sync="centerDialogVisible" width="500px" center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" style="padding: 0px 30px 0 10px" label-width="80px" class="demo-ruleForm">
            <el-row>
                <el-col span="12">
                    <el-form-item label="面值" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col span="12">
                    <el-form-item label="赠送金额" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="标记" prop="region">
                <el-autocomplete style="width: 100%;" class="inline-input" v-model="state1"
                    :fetch-suggestions="querySearch" placeholder="请选择标记内容" @select="handleSelect"></el-autocomplete>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="medium" @click="centerDialogVisible = false">取 消</el-button>
            <el-button size="medium" type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            centerDialogVisible: false,
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请选择活动区域', trigger: 'change' }
                ],
                date1: [
                    { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                ],
                date2: [
                    { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
                ],
                type: [
                    { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
                ],
                resource: [
                    { required: true, message: '请选择活动资源', trigger: 'change' }
                ],
                desc: [
                    { required: true, message: '请填写活动形式', trigger: 'blur' }
                ]
            },
        };
    },
    created() {

    },
    methods: {
        init() {
            this.centerDialogVisible = true
        }
    }
};
</script>