<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="创建时间：" prop="industryType">
              <el-date-picker style="width: 100%;" @change="change" v-model="valuechange" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd hh:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="储值卡卡号：" prop="id">
              <el-input v-model="queryInfo.condition.cardNo" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="储值卡名称：" prop="businessName">
              <el-input v-model="queryInfo.condition.cardName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="8">
            <el-form-item label="领取人名称：" prop="applicantTime">
              <el-input v-model="queryInfo.condition.productName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="领取人手机号：" prop="linkPhone">
              <el-input v-model="queryInfo.condition.phone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="标记内容：" prop="scope">
              <el-input v-model="queryInfo.condition.markContent" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="兑换状态：" prop="scope">
              <el-select v-model="queryInfo.condition.isUse" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <div class="tab" v-show="tapshow == 0">
      <div style="display: flex; justify-content: space-between;">
        <div>
          <el-button type="primary" @click="openAdd">新增储值卡</el-button>
          <el-button type="primary" @click="openMarkers">标记</el-button>
        </div>
        <div>
          <el-button type="primary" @click="openEdit">编辑</el-button>
          <el-button type="primary" @click="toExport">导出</el-button>
        </div>
      </div>
      <el-table @selection-change="handleSelectionChange" :data="tableData" style="width: 100%"
        height="calc(100vh - 420px)" border :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column width="170" prop="id" label="储值卡ID" align="center">
        </el-table-column>
        <el-table-column prop="cardNo" label="卡号" width="160" align="center">
        </el-table-column>
        <el-table-column prop="cardPassword" width="150" label="储值卡密码" align="center">
        </el-table-column>
        <el-table-column prop="markContent" label="标记内容" width="100" align="center">
        </el-table-column>
        <el-table-column prop="cardName" width="150" label="储值卡名称" align="center">
        </el-table-column>
        <el-table-column prop="faceValue" label="面值" align="center">
        </el-table-column>
        <el-table-column prop="giveValue" width="150" label="赠送额度" align="center">
        </el-table-column>
        
        <el-table-column prop="isActivation" width="150" label="兑换状态" align="center">
          <template slot-scope="scope">
            <el-button slot="reference" type="text">{{ scope.row.isUse == 1 ? '已兑换' : '待兑换' }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="memberName" label="用户名称" align="center">
        </el-table-column>
        <el-table-column prop="phone" width="150" label="用户手机号" align="center">
        </el-table-column>
        <!-- <el-table-column prop="memberName" width="150" label="领取人姓名" align="center">
        </el-table-column>
        <el-table-column prop="phone" width="150" label="领取人手机号" align="center">
        </el-table-column>
        
        <el-table-column prop="creatTime" label="创建时间" width="150" align="center">
        </el-table-column> -->
        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-popconfirm title="确定删除吗？" @onConfirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="tab" v-show="tapshow == 1">
      <div class="add" style="text-align: right;">
        <el-button type="primary" @click="added">商家信息导入</el-button>
        <el-button type="primary" @click="added">导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">

        <el-table-column prop="businessId" label="订单号" width="160" align="center">
        </el-table-column>

        <el-table-column prop="commodityType" label="订单类型" align="center">
        </el-table-column>
        <el-table-column prop="totalNum" label="订单总额" width="100" align="center">
        </el-table-column>
        <el-table-column prop="totalNum" label="商品费用" align="center">
        </el-table-column>
        <el-table-column prop="platformNum" label="配送费" align="center">
        </el-table-column>
        <el-table-column prop="tipNum" label="小费" width="100" align="center">
        </el-table-column>
        <el-table-column prop="productName" label="物品信息" width="150" align="center">
        </el-table-column>
        <el-table-column prop="payChanel" label="支付方式" align="center">
        </el-table-column>
        <el-table-column prop="nikeName" label="客户昵称" align="center">
        </el-table-column>
        <el-table-column prop="userName" label="客户姓名" align="center">
        </el-table-column>
        <el-table-column prop="userPhone" label="手机号码" align="center">
        </el-table-column>
        <el-table-column prop="userOrderStatus" label="订单状态" align="center">
        </el-table-column>
        <el-table-column prop="isDesignate" label="指定购买地址" align="center">
        </el-table-column>
        <el-table-column prop="address" label="收货地址" align="center">
        </el-table-column>
        <el-table-column prop="distributionUserName" label="配送员" align="center">
        </el-table-column>
        <el-table-column prop="distriPhone" label="配送员手机号" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="下单时间" width="150" align="center">
        </el-table-column>
        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
            <el-button type="text" @click="deta(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :visible.sync="showDialogtwo" width="1000px" height="1000px">
      <div class="diatwo">
        <div class="order1">
          <div style="color: #1e98d7;">帮我买订单</div>
          <div>订单编号:{{ detail.id }}</div>
        </div>
        <div class="order2">
          <div class="li">当前订单状态：{{
            detail.userOrderStatus == 1 ? '购物车' :
              detail.userOrderStatus == 2 ? '待接单' :
                detail.userOrderStatus == 3 ? '进行中' :
                  detail.userOrderStatus == 4 ? '待核销' :
                    detail.userOrderStatus == 5 ? '已完成' :
                      detail.userOrderStatus == 6 ? '已完成' :
                        detail.userOrderStatus == 7 ? '已评价' : '售后'
          }}</div>
          <div class="li">订单类型：{{ detail.commodityType == 1 ? '团购' :
            detail.commodityType == 2 ? '外卖' :
              detail.commodityType == 3 ? '积分商品' :
                detail.commodityType == 4 ? '帮我买' : '帮我送'
            }}</div>

        </div>
        <div class="order3">客户信息</div>
        <div class="order4">
          <div>客户昵称 :{{ detail.nikeName }}</div>
          <div>客户手机号: {{ detail.userPhone }}</div>
          <div>指定购买地址: 72819729</div>
          <div>收货地址: {{ detail.areaName }}</div>
        </div>
        <div class="order3">骑手信息</div>
        <div class="order4">
          <div>骑手姓名 :{{ detail.distributionUserName }}</div>
          <div>骑手手机号: {{ detail.distriPhone }}</div>
        </div>
        <div class="order3">订单信息</div>
        <div class="order4">
          <div>下单时间 :{{ detail.creatTime }}</div>
          <div>支付方式: {{ detail.payChanel }}</div>
          <div>订单备注: {{ detail.orderRemark }}</div>

        </div>
        <div class="order5">
          <el-table :data="tableData" style="width: 100%" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
            <el-table-column prop="id" label="收货凭证" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="物品信息" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="物品价格" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="配送费" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="小费" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="总价" width="160" align="center">
            </el-table-column>
            <el-table-column prop="id" label="订单状态" width="160" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: right;color: #1e98d7;">实收款: 24元</div>
      </div>
    </el-dialog>
    <!-- 新增储存卡 -->
    <add ref="addRef" @refresh="getList"></add>
    <!-- 标记 -->
    <markers ref="markersRef"></markers>
    <!-- 编辑 -->
    <edit ref="editRef"></edit>
    <el-dialog title="标记礼品卡" :visible.sync="centerDialogVisible" width="500px" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" style="padding: 0px 30px 0 10px" label-width="80px"
        class="demo-ruleForm">
        <el-form-item label="标记内容" prop="name">
          <el-input v-model="ruleForm.markContent"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="centerDialogVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="bjsub">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑礼品卡" :visible.sync="centerDialogVisibles" width="500px" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForms" style="padding: 0px 30px 0 10px" label-width="80px"
        class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="面值" prop="name">
              <el-input v-model="ruleForms.faceValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赠送金额" prop="name">
              <el-input v-model="ruleForms.giveValue"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="标记" prop="region">
          <el-input v-model="ruleForms.markContent" placeholder="支持为空，为空时清空标记"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="centerDialogVisibles = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="bjsubs">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import add from './giftlist/giftlistAdd.vue' // 新增储存卡
import markers from './giftlist/giftlistMarkers.vue' // 标记
import edit from './giftlist/giftlistEdit.vue' // 编辑
export default {
  components: { add, markers, edit },
  data() {
    return {
      showDialogtwo: false,
      centerDialogVisible: false,//标记
      centerDialogVisibles: false,//标记
      ruleForm: {},
      ruleForms: {},
      toplist: [{
        name: '已兑换',
        value: ''
      }],
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          cardNo: "",
          markContent: "",
          phone: "",
          cardName: "",
          isUse: "",
          startDate: "",
          endDate: ""
        }
      },
      rules: {},
      valuechange: '',
      form: {},
      tapshow: 0,
      options: [{
        value: '',
        label: '全部'
      }, {
        value: '1',
        label: '已兑换'
      }, {
        value: '2',
        label: '待兑换'
      }],
      radio: '1',
      detail: {},
      xzlist: [],//选择的行

    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    // 新增储存卡-打开弹窗
    openAdd() {
      // this.centerDialogVisibles =true
      this.$refs.addRef.init()
    },
    // 标记-打开弹窗
    openMarkers() {
      this.centerDialogVisible = true
      // this.$refs.markersRef.init()
    },
    // 标记-打开弹窗
    openEdit() {
      this.centerDialogVisibles = true
      // this.$refs.editRef.init()
    },
    change() {
      console.log(this.valuechange);
      this.queryInfo.condition.startDate = this.valuechange[0]
      this.queryInfo.condition.endDate = this.valuechange[1]
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/giftcards/list", that.queryInfo).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;

        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      // this.queryInfo.condition = this.form
      this.getList()
    },
    reset() {
      // this.form = {}
      this.queryInfo.condition = {}
      this.queryInfo.condition.totalOrderType = this.tapshow + 1
      this.getList()
    },
    tapchange(i) {
      this.tapshow = i
      if (i == 0) {
        this.queryInfo.condition.isActivation = 1
        this.getList()
      }
      if (i == 1) {
        this.queryInfo.condition.totalOrderType = 2
        this.getList()
      }

    },
    deta(id) {
      var that = this;
      that.$http.post("/order/adminQueryOrderDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.detail = response.data.data
          that.showDialogtwo = true
        }
      });
    },
    handleSelectionChange(val) {
      this.xzlist = val
    },
    // 导出
    async toExport() {
      const that = this
      const _data = {
        ...that.queryInfo.condition
      }
      // const res = await this.$http.post('/message/exportWord', data)
      const res = await this.$http({
        method: 'post',
        url: '/giftcards/export',
        // responseType: "blob",
        data: _data
      });
      console.log(res);
      if (res.status == 200) {
        // if(res.data.code == '1'){
        //   const link = document.createElement("a");
        //   link.href = res.data.data;
        //   link.setAttribute("download", `礼品卡列表.xls`); // 设置导出的文件名称
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
        //   that.$message({type:'success',message: '导出成功'})
        // }else{

        // }
        try {
          const blob = new Blob([res.data]); // 创建Blob对象
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `礼品卡列表.xls`); // 设置导出的文件名称
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          that.$message({ type: 'success', message: '导出成功' })
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
    bjsub() {
      var that = this;
      that.$http.post("/giftcards/updateBatch", {
        markContent: that.ruleForm.markContent,
        giftcardsList: that.xzlist
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.centerDialogVisible = false
          that.ruleForm.markContent = ''
          that.getList()
          that.$message({
            type: 'success',
            message: '标记成功'
          })
        }
      });

    },
    bjsubs() {
      var that = this;
      that.$http.post("/giftcards/updateBatch", {
        faceValue: that.ruleForms.faceValue,
        giveValue: that.ruleForms.giveValue,
        markContent: that.ruleForms.markContent,
        giftcardsList: that.xzlist
      }).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.centerDialogVisibles = false
          that.getList()
          that.ruleForms.faceValue = ''
          that.ruleForms.giveValue = ''
          that.ruleForms.markContent = ''
          that.$message({
            type: 'success',
            message: '成功'
          })
        } else {
          that.$message({
            type: 'error',
            message: response.data.message
          })
        }
      });

    },
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/giftcards/delete", { "id": id }).then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }

        });
      }
    },
    added() { },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;

    .search-top {
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
        height: 33px;
        line-height: 33px;
      }

      .active {
        border-bottom: 2px solid #2097f6;
        color: #2097f6;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .order1 {
      display: flex;
      margin-bottom: 20px;

      div {
        margin-right: 20px;
      }
    }

    .order2 {
      display: flex;

      .li {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        text-align: center;
        padding-top: 30px;

        margin-right: 20px;
      }

      .li2 {
        width: 206px;
        height: 80px;
        padding: 2px 2px 2px 2px;
        border: 1px solid #1e98d7;
        background-color: rgba(30, 152, 215, 0.06);
        box-sizing: border-box;
        color: #1e98d7;
        align-items: center;


        margin-right: 20px;
        display: flex;

        .li-left {
          color: rgba(251, 142, 0, 0.99);
          margin-left: 10px;
          margin-right: 10px;
        }

        .li-right {
          padding-top: 15px;
        }
      }
    }

    .order3 {
      font-weight: 700;
      color: #333333;
      margin-top: 30px;
    }

    .order4 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      div {
        width: 50%;
        margin-top: 10px;
      }
    }

  }

  .diasale {
    color: #333333;
    font-size: 16px;

    .sale1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .sale2 {
      margin-top: 5px;

      .de2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .de-left {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
          }

          .de-jj {
            margin-left: 5px;

            div {
              height: 33px;
              line-height: 33px;
            }

          }
        }

        .de-right {
          color: #e30606;
        }
      }

      .de3 {
        margin-top: 10px;

        .de-img {
          display: flex;

          img {
            width: 66px;
            height: 68px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }

        border-bottom: 1px solid #e30606;
        padding-bottom: 40px
      }
    }

    .sale3 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px
    }

    .sale4 {
      margin-top: 20px;
      display: flex;

      textarea {
        width: 680px;
        height: 60px;
        padding: 2px 2px 2px 2px;
        border-radius: 5px;
        border: 1px solid rgba(121, 121, 121, 0.26);
      }
    }
  }
}
</style>