import { render, staticRenderFns } from "./giftlistEdit.vue?vue&type=template&id=16ee5c6f"
import script from "./giftlistEdit.vue?vue&type=script&lang=js"
export * from "./giftlistEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "giftlistEdit.vue"
export default component.exports