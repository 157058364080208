import { render, staticRenderFns } from "./giftlist.vue?vue&type=template&id=149052a0&scoped=true"
import script from "./giftlist.vue?vue&type=script&lang=js"
export * from "./giftlist.vue?vue&type=script&lang=js"
import style0 from "./giftlist.vue?vue&type=style&index=0&id=149052a0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149052a0",
  null
  
)

component.options.__file = "giftlist.vue"
export default component.exports